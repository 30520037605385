import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function ConnectKoaListIcon(props: SvgIconProps) {
    return (
        <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M15.8332 2.5H4.1665V17.5H15.8332V2.5Z'
                stroke='white'
                strokeWidth='1.66667'
                strokeLinejoin='round'
                fill='none'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.16667 3.33301H0.833333C0.61232 3.33301 0.400358 3.42081 0.244078 3.57709C0.0877974 3.73337 0 3.94533 0 4.16634L0 15.833C0 16.054 0.0877974 16.266 0.244078 16.4223C0.400358 16.5785 0.61232 16.6663 0.833333 16.6663H4.16667V14.9997H1.66667V4.99967H4.16667V3.33301ZM15.8333 4.99967V3.33301H19.1667C19.3877 3.33301 19.5996 3.42081 19.7559 3.57709C19.9122 3.73337 20 3.94533 20 4.16634V15.833C20 16.054 19.9122 16.266 19.7559 16.4223C19.5996 16.5785 19.3877 16.6663 19.1667 16.6663H15.8333V14.9997H18.3333V4.99967H15.8333Z'
                fill='white'
            />
            <path
                d='M4.1665 12.5H15.8332M4.1665 7.5H15.8332M9.99984 5H13.3332M9.99984 10H13.3332M9.99984 15H13.3332'
                stroke='white'
                strokeWidth='1.66667'
                strokeLinejoin='round'
            />
            <path
                d='M7.49984 5.83366C7.72085 5.83366 7.93281 5.74586 8.08909 5.58958C8.24537 5.4333 8.33317 5.22134 8.33317 5.00033C8.33317 4.77931 8.24537 4.56735 8.08909 4.41107C7.93281 4.25479 7.72085 4.16699 7.49984 4.16699C7.27882 4.16699 7.06686 4.25479 6.91058 4.41107C6.7543 4.56735 6.6665 4.77931 6.6665 5.00033C6.6665 5.22134 6.7543 5.4333 6.91058 5.58958C7.06686 5.74586 7.27882 5.83366 7.49984 5.83366ZM7.49984 10.8337C7.72085 10.8337 7.93281 10.7459 8.08909 10.5896C8.24537 10.4333 8.33317 10.2213 8.33317 10.0003C8.33317 9.77931 8.24537 9.56735 8.08909 9.41107C7.93281 9.25479 7.72085 9.16699 7.49984 9.16699C7.27882 9.16699 7.06686 9.25479 6.91058 9.41107C6.7543 9.56735 6.6665 9.77931 6.6665 10.0003C6.6665 10.2213 6.7543 10.4333 6.91058 10.5896C7.06686 10.7459 7.27882 10.8337 7.49984 10.8337ZM7.49984 15.8337C7.72085 15.8337 7.93281 15.7459 8.08909 15.5896C8.24537 15.4333 8.33317 15.2213 8.33317 15.0003C8.33317 14.7793 8.24537 14.5674 8.08909 14.4111C7.93281 14.2548 7.72085 14.167 7.49984 14.167C7.27882 14.167 7.06686 14.2548 6.91058 14.4111C6.7543 14.5674 6.6665 14.7793 6.6665 15.0003C6.6665 15.2213 6.7543 15.4333 6.91058 15.5896C7.06686 15.7459 7.27882 15.8337 7.49984 15.8337Z'
                fill='white'
            />
        </SvgIcon>
    );
}
