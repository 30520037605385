import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function ConnectKoaAirplaneIcon(props: SvgIconProps) {
    return (
        <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M19.7326 1.82347C19.6142 1.69448 19.4639 1.59887 19.2968 1.54623C19.1297 1.49359 18.9518 1.48575 18.7807 1.5235L0.782692 5.52307C0.590108 5.56593 0.414507 5.6648 0.277995 5.80725C0.141484 5.94969 0.0501623 6.12933 0.0155278 6.32356C-0.0191066 6.51779 0.00449379 6.71793 0.0833582 6.89878C0.162223 7.07962 0.292826 7.2331 0.458727 7.33988L5.99913 10.9005V17.4998C5.99906 17.6882 6.05222 17.8728 6.15249 18.0323C6.25277 18.1918 6.39607 18.3197 6.56589 18.4014C6.7357 18.483 6.92512 18.515 7.11232 18.4936C7.29952 18.4723 7.47687 18.3985 7.62395 18.2807L12.0125 14.7691L14.4572 16.3409C14.5871 16.425 14.7347 16.4779 14.8885 16.4953C15.0423 16.5126 15.198 16.4941 15.3434 16.4411C15.4888 16.3881 15.6199 16.302 15.7264 16.1897C15.8328 16.0774 15.9118 15.942 15.9571 15.794L19.9566 2.79537C20.0076 2.62794 20.0138 2.45005 19.9745 2.2795C19.9352 2.10895 19.8518 1.9517 19.7326 1.82347ZM13.9893 4.63917L7.09901 9.23267L3.55839 6.95692L13.9893 4.63917ZM7.99891 15.419V12.1894L10.2377 13.6282L7.99891 15.419ZM14.4302 13.9462L8.93081 10.4125L17.2169 4.89114L14.4302 13.9462Z'
                fill='white'
            />
        </SvgIcon>
    );
}
