import { SupplyFilterType } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

export const SELECTED_PRESET_KEY = 'selected-preset-id';
export const PAGE_SIZE_KEY = 'page-size';
export const FILTER_SET_ID_KEY = 'filterSetId';
export const FILTER_SIDEBAR_OPEN_KEY = 'filter-sidebar-open';
export const SELECTED_AUCTION_DATE_KEY = 'selected-auction-date';
export const SELECTED_SUPPLY_DETAIL_TAB_KEY = 'selected-supply-detail-tab';
export function FILTERMENU_OPEN_KEY(filterType: SupplyFilterType) {
    return `is-filtermenu-${filterType}-open`;
}
