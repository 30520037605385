import { createContext, useContext, useMemo, useState, PropsWithChildren } from 'react';
import { ConnectKOA } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

interface KOAContextValue {
    koaInProgress: boolean;
    administrator?: ConnectKOA;
    setKoaInProgress: (inProgress: boolean) => void;
    setAdministrator: (user: ConnectKOA) => void;
}

const KOAContext = createContext<KOAContextValue>({
    koaInProgress: false,
    setKoaInProgress: () => undefined,
    setAdministrator: () => undefined,
});

export function KOAProvider(props: Readonly<PropsWithChildren>) {
    const { children } = props;
    const [administrator, setAdministrator] = useState<ConnectKOA>();
    const [koaInProgress, setKoaInProgress] = useState<boolean>(false);

    const value = useMemo<KOAContextValue>(
        () => ({
            koaInProgress,
            administrator,
            setAdministrator,
            setKoaInProgress,
        }),
        [koaInProgress, administrator],
    );

    return <KOAContext.Provider value={value}>{children}</KOAContext.Provider>;
}

export function useKOAContext(): KOAContextValue {
    return useContext(KOAContext);
}
