import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
    button: {
        width: 'fit-content',
    },
}));
