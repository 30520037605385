/* eslint-disable no-console */
import {
    SupplyFilterType,
    SupplyRangeFilterType,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

export function getFilterTypeTranslationKey(supplyFilterType: SupplyFilterType) {
    switch (supplyFilterType) {
        case SupplyFilterType.MainGroup:
            return 'mainGroup';
        case SupplyFilterType.ProductGroup:
            return 'productGroup';
        case SupplyFilterType.Product:
            return 'product';
        case SupplyFilterType.PotSize:
            return 'potSize';
        case SupplyFilterType.Supplier:
            return 'supplier';
        case SupplyFilterType.Package:
            return 'package';
        case SupplyFilterType.Quality:
            return 'quality';
        case SupplyFilterType.FlowerStemLength:
            return 'flowerStemLength';
        case SupplyFilterType.Weight:
            return 'weight';
        case SupplyFilterType.Marking:
            return 'marking';
        case SupplyFilterType.Certificates:
            return 'certificates';
        case SupplyFilterType.Presale:
            return 'presale';
        case SupplyFilterType.Clock:
            return 'clock';
        case SupplyFilterType.PlantHeight:
            return 'plantHeight';
        case SupplyFilterType.MainColor:
            return 'mainColor';
        case SupplyFilterType.CountryOfOrigin:
            return 'countryOfOrigin';
        case SupplyFilterType.AuctionLocation:
            return 'auctionLocation';
        case SupplyFilterType.MaturityStage:
            return 'maturityStage';
        case SupplyFilterType.MinimumBranchesPerPlant:
            return 'minimumBranchesPerPlant';
        case SupplyFilterType.AuctionGroup:
            return 'auctionGroup';
        default:
            console.error(supplyFilterType + ' not implemented in getFilterTypeTranslationKey');
            return '';
    }
}

export function getSupplyFilterTypeByString(supplyFilterType: string) {
    switch (supplyFilterType) {
        case 'MainGroup':
            return SupplyFilterType.MainGroup;
        case 'ProductGroup':
            return SupplyFilterType.ProductGroup;
        case 'Product':
            return SupplyFilterType.Product;
        case 'PotSize':
            return SupplyFilterType.PotSize;
        case 'Supplier':
            return SupplyFilterType.Supplier;
        case 'Package':
            return SupplyFilterType.Package;
        case 'Quality':
            return SupplyFilterType.Quality;
        case 'FlowerStemLength':
            return SupplyFilterType.FlowerStemLength;
        case 'Weight':
            return SupplyFilterType.Weight;
        case 'Marking':
            return SupplyFilterType.Marking;
        case 'Certificates':
            return SupplyFilterType.Certificates;
        case 'Presale':
            return SupplyFilterType.Presale;
        case 'Clock':
            return SupplyFilterType.Clock;
        case 'PlantHeight':
            return SupplyFilterType.PlantHeight;
        case 'MainColor':
            return SupplyFilterType.MainColor;
        case 'CountryOfOrigin':
            return SupplyFilterType.CountryOfOrigin;
        case 'AuctionLocation':
            return SupplyFilterType.AuctionLocation;
        case 'MaturityStage':
            return SupplyFilterType.MaturityStage;
        case 'MinimumBranchesPerPlant':
            return SupplyFilterType.MinimumBranchesPerPlant;
        case 'AuctionGroup':
            return SupplyFilterType.AuctionGroup;
        default:
            console.error(supplyFilterType + ' not implemented in getSupplyFilterTypeByString');
            return '';
    }
}

export function getSupplyRangeFilterType(supplyFilterType: SupplyFilterType) {
    // Sonarlint complains when switch statement has less than 3 cases. Refactor to switch when we have more cases.
    if (supplyFilterType === SupplyFilterType.FlowerStemLength) return SupplyRangeFilterType.FlowerStemLength;
    if (supplyFilterType === SupplyFilterType.PotSize) return SupplyRangeFilterType.PotSize;
    if (supplyFilterType === SupplyFilterType.PlantHeight) return SupplyRangeFilterType.PlantHeight;

    console.error(supplyFilterType + ' does not have a range filter type');
    return '';
}

export const getSupplyRangeFilterTypeUnit: Record<SupplyRangeFilterType, string> = {
    [SupplyRangeFilterType.FlowerStemLength]: 'cm',
    [SupplyRangeFilterType.PlantHeight]: 'cm',
    [SupplyRangeFilterType.PotSize]: 'cm',
};
