import { MailIcon } from '@floriday/floriday-icons';
import { Box } from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';

import ConnectKoaAirplaneIcon from '@resources/Icons/ConnectKoaAirplaneIcon';
import ConnectKoaListIcon from '@resources/Icons/ConnectKoaListIcon';
import { useStyles } from './StepIconStyles';

export default function StepIcon(props: Readonly<StepIconProps>) {
    const { icon } = props;
    const { classes } = useStyles();

    const icons: { [index: string]: React.ReactElement<unknown> } = {
        1: <ConnectKoaListIcon />,
        2: <MailIcon className={classes.mailIcon} />,
        3: <ConnectKoaAirplaneIcon />,
    };

    return <Box className={classes.stepContainer}>{icons[String(icon)]}</Box>;
}
