import { useEffect } from 'react';
import { OverlayLoadingPage } from '@floriday/floriday-ui';
import { Grid2 as Grid, Typography } from '@mui/material';
import {
    ClockSupplySearchCommand,
    SortDirection,
    SortField,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useTranslation } from 'react-i18next';

import { useMarking } from '@features/Supply/Hooks/useMarking';
import { useMarkingAccounts } from '@features/Supply/Hooks/useMarkingAccounts';
import { useSupplyLines } from '@features/Supply/Hooks/useSupplyLines';
import { useUserGroup } from '@features/Supply/Hooks/useUserGroup';
import { useNavigatorOnLine } from '@root/Hooks/useNavigatorOnLine';
import { namespaces } from '@root/i18n';
import Empty from '@resources/Icons/Empty';
import { addMarkingUsersForSupplyLines } from '@store/Actions/markingUsersActions';
import { updateMarkings } from '@store/Actions/markingsActions';
import { useMarkingUsersContext } from '@store/Providers/MarkingUsersProvider';
import { useMarkingsContext } from '@store/Providers/MarkingsProvider';
import { useUserSettings } from '@store/Providers/UserSettingsProvider';
import { getDefaultAuctionDate } from '@utils/DateUtils';
import MockedScanTable from './Components/MockedScanTable/MockedScanTable';
import ScanTable from './Components/ScanTable/ScanTable';
import { useStyles } from './ScanResultStyles';

export interface ISupplyTableProps {
    readonly barcode: string;
}

export default function ScanResult(props: ISupplyTableProps) {
    const { barcode } = props;
    const auctionDate = getDefaultAuctionDate();
    const searchCommand: ClockSupplySearchCommand = {
        query: barcode,
        skip: 0,
        take: 100,
        sorting: {
            field: SortField.AuctioningSequence,
            direction: SortDirection.Ascending,
        },
        hasMarking: false,
        hasPresale: false,
        searchFilterItems: [],
        searchRangeFilterItems: [],
        auctionDate,
        returnAggregations: false,
    };

    const { isLoading, isError, data: supplyLines } = useSupplyLines(searchCommand);
    const { t } = useTranslation(namespaces.supply);
    const { dispatch } = useMarkingsContext();
    const { classes } = useStyles();
    const isOnline = useNavigatorOnLine();

    const { data: markingAccounts } = useMarkingAccounts();
    const supplier = supplyLines?.results[0]?.organization?.name;

    const { userSettings } = useUserSettings();
    const { createMarkings } = useMarking();
    const { data: markingGroup } = useUserGroup();
    const markingGroupUsers = markingGroup?.users ?? [];
    const { dispatch: markingUsersDispatch } = useMarkingUsersContext();

    useEffect(() => {
        const markings = supplyLines?.markings;
        if (markings) dispatch(updateMarkings(markings, auctionDate));
    }, [supplyLines]);

    useEffect(() => {
        if (supplyLines && userSettings.markDirectlyOnScan) {
            const supplyLineIds = supplyLines?.results.map(supplyLine => supplyLine.id ?? '') ?? [];

            if (userSettings.markForGroup) {
                markingUsersDispatch(addMarkingUsersForSupplyLines(supplyLineIds, markingGroupUsers));

                markingGroupUsers.forEach(user => {
                    createMarkings.mutate({
                        supplyLines: supplyLineIds,
                        username: user,
                    });
                });
            } else if (!userSettings.markForGroup) {
                createMarkings.mutate({
                    supplyLines: supplyLineIds,
                });
            }
        }
    }, [barcode, supplyLines, userSettings, markingGroupUsers]);

    return (
        <OverlayLoadingPage showLoadingOverlay={isLoading && isOnline}>
            {isError && <Empty />}
            {!isError && (
                <Grid container spacing={1} className={classes.container} data-testid='scanTable'>
                    <Grid size={{ xs: 12 }}>
                        <Typography align='left' className={`${classes.typography} ${classes.header}`}>
                            {t('scan.scannedBatch')}
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                        <Typography variant='body1' align='left' className={`${classes.typography} ${classes.label}`}>
                            {t('scan.supplier')}
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                        <Typography variant='body1' align='right' className={`${classes.typography} ${classes.label}`}>
                            {t('scan.barcode')}
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                        <Typography
                            variant='body1'
                            align='left'
                            className={`${classes.typography} ${!supplier ? classes.unavailable : ''}`}
                            data-testid='supplier'
                        >
                            {supplier ?? t('scan.unavailable')}
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                        <Typography variant='body1' align='right' className={classes.typography} data-testid='barcode'>
                            {barcode}
                        </Typography>
                    </Grid>

                    <Grid size={{ xs: 12 }}>
                        {isOnline ? (
                            <ScanTable
                                clockSupplyLines={supplyLines?.results ?? []}
                                markingAccounts={markingAccounts?.accounts ?? []}
                            />
                        ) : (
                            <MockedScanTable barcode={barcode} />
                        )}
                    </Grid>
                </Grid>
            )}
        </OverlayLoadingPage>
    );
}
