import { lazy } from 'react';
import * as Sentry from '@sentry/react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';

import SuspenseRoute from '@root/Components/SuspenseRoute/SuspenseRoute';
import ConnectKOA from './Pages/ConnectKOA/ConnectKOA';
import FilterSetDetail from './Pages/FilterSetDetail/FilterSetDetail';
import FilterSetOverview from './Pages/FilterSetOverview/FilterSetOverview';
import Frame from './Pages/Frame/Frame';
import LoginCallback from './Pages/LoginCallback/LoginCallback';
import ScanOverview from './Pages/Scan/ScanOverview';

const SupplyOverview = lazy(() => import('./Pages/SupplyOverview/SupplyOverview'));

export default function AppRoutes() {
    const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
    const router = sentryCreateBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path='/signin-callback' element={<LoginCallback />} />
                <Route path='*' element={<Frame />}>
                    <Route path='*' element={<SuspenseRoute />}>
                        <Route path={'filter-sets'} element={<FilterSetOverview />} />
                        <Route path={'filter-sets/new'} element={<FilterSetDetail />} />
                        <Route path={'filter-sets/:id'} element={<FilterSetDetail />} />
                        <Route path={'scan'} element={<ScanOverview />} />
                        <Route path={'connect-koa'} element={<ConnectKOA />} />
                        <Route path={''} element={<Navigate to={'supply-overview'} />} />
                        <Route path={'*'} element={<SupplyOverview />} />
                    </Route>
                </Route>
            </>,
        ),
    );

    return <RouterProvider router={router} />;
}
