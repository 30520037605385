import { purple } from '@floriday/floriday-ui/es/Styles/colors';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    icon: {
        color: purple.main,
        width: '16px',
        height: '16px',
    },
    button: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        padding: '0',
        borderRadius: '0',
        [theme.breakpoints.down('sm')]: {
            height: '40px',
            width: '40px',
        },
        width: '24px',
        height: '24px',
    },
}));
