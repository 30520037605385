import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    steps: {
        backgroundColor: theme.palette.grey[50],
    },
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
    stepsInfo: {
        whiteSpace: 'pre-line',
    },
    stepActive: {
        opacity: '100%',
    },
    stepConnector: {
        width: '2px',
        transform: 'translate(10px)',
        '.MuiStepConnector-line': {
            borderColor: 'white',
            borderWidth: '2px',
        },
    },
    stepLabel: {
        padding: 0,
        opacity: '40%',
        'span.MuiStepLabel-label': {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
        },
    },
    stepDescription: {
        color: theme.palette.primary.main,
    },
    footer: {
        marginTop: 'auto',
    },
    footerText: {
        display: 'flex',
        gap: theme.spacing(2),
        padding: theme.spacing(1, 0),
    },
    playButtonIcon: {
        fill: 'none',
    },
    helpCenterText: {
        color: theme.palette.primary.main,
    },
}));
