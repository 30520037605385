import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function PlayButtonIcon(props: SvgIconProps) {
    return (
        <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#clip0_2169_76)'>
                <path
                    d='M2.8335 3.66699H21.1668V20.3337H2.8335V3.66699Z'
                    stroke='#351379'
                    strokeWidth='1.66667'
                    strokeLinejoin='round'
                />
                <path
                    d='M7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7C10.6739 7 9.40215 7.52678 8.46447 8.46447C7.52678 9.40215 7 10.6739 7 12Z'
                    stroke='#351379'
                    strokeWidth='1.66667'
                    strokeLinejoin='round'
                />
                <path d='M14.4998 12L11.1665 9.5V14.5L14.4998 12Z' fill='#351379' />
            </g>
            <defs>
                <clipPath id='clip0_2169_76'>
                    <rect width='20' height='20' fill='white' transform='translate(2 2)' />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
