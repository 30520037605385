import { createContext, useContext, PropsWithChildren, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import AppsPortalClient from '@api/AppsPortalClient';
import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { authenticatedRequest, handleApiGetError } from '@api/helper';

interface IFeatureFlagsContextValue {
    kvvVisible: boolean;
    markingAutomationsVisible: boolean;
    hasScanVisible: boolean;
    hasAuctionBuyerManagement: boolean;
    isOrganizationAdministrator: boolean;
}

const FeatureFlagsContext = createContext<IFeatureFlagsContextValue>({
    kvvVisible: false,
    markingAutomationsVisible: false,
    hasScanVisible: false,
    hasAuctionBuyerManagement: false,
    isOrganizationAdministrator: false,
});

const client = AuctionPreparationClient.instance.getApiClient();
const appsPortalClient = AppsPortalClient.instance.getApiClient();

export function FeatureFlagsProvider({ children }: Readonly<PropsWithChildren>) {
    const kvvVisible = false;

    function getMarkingAutomationFeatureToggle() {
        return client.getMarkingAutomationFeatureToggle();
    }

    const { data: userAllowedForMarkingAutomation } = useQuery(
        ['marking-automation-feature-toggle'],
        () => authenticatedRequest(getMarkingAutomationFeatureToggle),
        {
            onError: error => {
                handleApiGetError(error);
            },
        },
    );

    function getScanFeatureToggle() {
        return client.getScanFeatureToggle();
    }

    const { data: userAllowedForScan } = useQuery(
        ['scan-feature-toggle'],
        () => authenticatedRequest(getScanFeatureToggle),
        {
            onError: error => {
                handleApiGetError(error);
            },
        },
    );

    const { data: userAuthorizations } = useQuery(
        ['auction-buyer-management-feature-toggle'],
        () => authenticatedRequest(() => appsPortalClient.getUserAuthorization()),
        {
            onError: error => {
                handleApiGetError(error, false);
            },
        },
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hasAuctionBuyerManagementFeature = userAuthorizations?.some((org: any) =>
        org.appFeatures.includes('auction-buyer-management'),
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isOrganizationAdministratorFeature = userAuthorizations?.some((org: any) => org.isAdministrator);

    const value = useMemo<IFeatureFlagsContextValue>(
        () => ({
            kvvVisible,
            markingAutomationsVisible: userAllowedForMarkingAutomation ?? false,
            hasScanVisible: userAllowedForScan ?? false,
            hasAuctionBuyerManagement: hasAuctionBuyerManagementFeature,
            isOrganizationAdministrator: isOrganizationAdministratorFeature,
        }),
        [userAllowedForMarkingAutomation, userAllowedForScan, userAuthorizations],
    );

    return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
}

export function useFeatureFlags(): IFeatureFlagsContextValue {
    return useContext(FeatureFlagsContext);
}
