import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
    description: {
        whiteSpace: 'pre-line',
    },
    buttons: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
        paddingBottom: theme.spacing(10),
        p: {
            flexBasis: '100%',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    userContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));
