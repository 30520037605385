import { Dispatch, createContext, useReducer, useContext, PropsWithChildren, useMemo } from 'react';

import { MarkingUsersActions } from '@store/Actions/markingUsersActions';
import { initialState, MarkingUsersState, markingUsersReducer } from '@store/Reducers/markingUsersReducer';

interface IMarkingUsersContext {
    state: MarkingUsersState;
    dispatch: Dispatch<MarkingUsersActions>;
}

const initialContext: IMarkingUsersContext = {
    state: initialState,
    dispatch: () => undefined,
};

const MarkingsContext = createContext(initialContext);

export function MarkingUsersProvider(props: Readonly<PropsWithChildren>) {
    const { children } = props;
    const [state, dispatch] = useReducer(markingUsersReducer, initialState);

    const value = useMemo<IMarkingUsersContext>(
        () => ({
            state,
            dispatch,
        }),
        [state],
    );

    return <MarkingsContext.Provider value={value}>{children}</MarkingsContext.Provider>;
}

export function useMarkingUsersContext(): IMarkingUsersContext {
    return useContext(MarkingsContext);
}
