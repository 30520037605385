import { createContext, useState, useContext, PropsWithChildren, useMemo } from 'react';
import { Preset } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { authenticatedRequest, handleApiGetError } from '@api/helper';
import { SELECTED_PRESET_KEY } from '@constants/storageKeys';
import LoginError from '@features/Auth/Components/LoginError';
import { useConfigurationSettings } from '@root/Hooks/useConfigurationSettings';
import { namespaces } from '@root/i18n';
import { loadFromLocalStorage } from '@utils/LocalStorageUtils';

interface PresetsContextValue {
    presets: Preset[];
    selectedPreset?: Preset;
    setSelectedPreset: (preset: Preset) => void;
    setReadOnlyFloaterOpen: (open: boolean) => void;
    readOnlyFloaterOpen: boolean;
}

const PresetsContext = createContext<PresetsContextValue>({
    presets: [],
    selectedPreset: undefined,
    setSelectedPreset: () => undefined,
    setReadOnlyFloaterOpen: () => undefined,
    readOnlyFloaterOpen: false,
});

const client = AuctionPreparationClient.instance.getApiClient();

export function PresetsProvider(props: Readonly<PropsWithChildren>) {
    const { children } = props;
    const { t } = useTranslation(namespaces.general);
    const [presets, setPresets] = useState<Preset[]>([]);
    const [selectedPreset, setSelectedPreset] = useState<Preset | undefined>();
    const [readOnlyFloaterOpen, setReadOnlyFloaterOpen] = useState(true);
    const [isInitialized, setIsInitialized] = useState(false);
    const { data: configuration } = useConfigurationSettings();

    const value = useMemo<PresetsContextValue>(
        () => ({
            presets,
            selectedPreset,
            setSelectedPreset,
            setReadOnlyFloaterOpen,
            readOnlyFloaterOpen,
        }),
        [presets, selectedPreset, readOnlyFloaterOpen, setReadOnlyFloaterOpen],
    );

    function getUserPresets() {
        return client.getUserPresets();
    }

    useQuery(['user-presets', configuration], () => authenticatedRequest(getUserPresets), {
        onError: error => {
            setIsInitialized(true);
            handleApiGetError(error);
        },
        onSuccess: (userPresets: Preset[]) => {
            if (!!userPresets && userPresets.length !== 0) {
                setPresets(userPresets);
                if (selectedPreset === undefined) {
                    let presetToUse = userPresets[0]; // last fallback to the first preset
                    const presetFromLocalStorage = loadFromLocalStorage(SELECTED_PRESET_KEY);
                    if (
                        presetFromLocalStorage &&
                        userPresets.some(preset => preset.presetId === presetFromLocalStorage.presetId)
                    ) {
                        //if preset exists in local storage, and it is one of the presets to use
                        presetToUse = presetFromLocalStorage;
                    }

                    localStorage.setItem(SELECTED_PRESET_KEY, JSON.stringify(presetToUse));
                    setSelectedPreset(presetToUse);
                }
            }
            setIsInitialized(true);
        },
    });

    return (
        <PresetsContext.Provider value={value}>
            {isInitialized && presets.length !== 0 && children}
            {isInitialized && presets.length === 0 && (
                <LoginError
                    error={{
                        name: '',
                        message: t('genericErrors.noPresetsError'),
                    }}
                />
            )}
        </PresetsContext.Provider>
    );
}

export function usePresetContext(): PresetsContextValue {
    return useContext(PresetsContext);
}
