import { useEffect, useState } from 'react';
import { LoadingPage } from '@floriday/floriday-ui';
import { TokenManagerError } from '@okta/okta-auth-js';
import { useNavigate } from 'react-router-dom';

import { logToSentry } from '@api/helper';
import { ERRORS_TO_BE_IGNORED } from '@constants/authConstants';
import LoginError from '@features/Auth/Components/LoginError';
import { oktaAuthClient } from '@features/Auth/auth';

export default function LoginCallback() {
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState<Error>();

    async function handleLoginRedirect() {
        try {
            await oktaAuthClient.handleLoginRedirect();
            if (!oktaAuthClient.getOriginalUri()) {
                navigate('/');
            }
        } catch (error) {
            if (
                (error as TokenManagerError).errorCode !== 'login_required' &&
                !ERRORS_TO_BE_IGNORED.includes((error as TokenManagerError).errorSummary)
            ) {
                logToSentry('Okta', (error as Error).message ?? 'Error while signing in', 'error', error);
            }
            setLoginError(error as Error);
        }
    }

    async function checkAuthentication() {
        const isAuthenticated = await oktaAuthClient.isAuthenticated();
        if (!isAuthenticated) {
            oktaAuthClient.signInWithRedirect();
        } else {
            navigate('/');
        }
    }

    useEffect(() => {
        if (oktaAuthClient.isLoginRedirect()) {
            handleLoginRedirect();
        } else {
            checkAuthentication();
        }
    }, [navigate]);

    return loginError ? <LoginError error={loginError} /> : <LoadingPage />;
}
