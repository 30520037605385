import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { namespaces } from '@root/i18n';
import { useStyles } from './ConnectKOAInProgressStyles';

export default function ConnectKOAInProgress() {
    const { t } = useTranslation(namespaces.general);
    const { classes } = useStyles();
    const navigate = useNavigate();

    return (
        <div className={classes.container}>
            <Typography variant='h1'>{t('connectKoa.inProgress.title')}</Typography>
            <Typography>{t('connectKoa.inProgress.description')}</Typography>
            <Button
                className={classes.button}
                variant='contained'
                size='medium'
                color='primary'
                onClick={() => navigate('/supply-overview')}
            >
                {t('buttons.connectKOADone')}
            </Button>
        </div>
    );
}
