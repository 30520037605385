import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    stepContainer: {
        backgroundColor: theme.palette.primary.main,
        zIndex: 1,
        width: 48,
        height: 48,
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mailIcon: {
        fill: 'white',
    },
}));
