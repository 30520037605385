import { useEffect, useState } from 'react';
import { AddIcon } from '@floriday/floriday-icons';
import { Form, ResponsiveButton } from '@floriday/floriday-ui';
import { Box, Button, Typography } from '@mui/material';
import { ConnectKOA } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { getUserAccount } from '@features/Auth/auth';
import { namespaces } from '@root/i18n';
import { useActivityTracker } from '@store/Providers/ActivityTrackerProvider';
import { useKOAContext } from '@store/Providers/KOAProvider';
import AdministratorFormGroup from './Components/AdministratorFormGroup/AdministratorFormGroup';
import UserFormGroup from './Components/UserFormGroup/UserFormGroup';
import { useStyles } from './ConnectKOADataFormStyles';

export interface IConnectKOADataForm {
    readonly setStep: (step: number) => void;
}

export default function ConnectKOADataForm(props: IConnectKOADataForm) {
    const { setStep } = props;
    const { t } = useTranslation(namespaces.general);
    const { administrator, koaInProgress, setAdministrator, setKoaInProgress } = useKOAContext();

    const [users, setUsers] = useState<ConnectKOA[]>(administrator ? [administrator] : []);
    const [userCount, setUserCount] = useState(koaInProgress ? 2 : 1);

    const { classes } = useStyles();
    const { trackConnectKOARequestedActivity } = useActivityTracker();

    const client = AuctionPreparationClient.instance.getApiClient();

    const sendConnectKoaRequest = useMutation(() => client.sendConnectKoaRequest(users));

    function onDataConfirm() {
        sendConnectKoaRequest.mutate();

        setAdministrator(users[0]);
        setKoaInProgress(true);
        trackConnectKOARequestedActivity();
        setStep(1);
    }

    function onUserChange(user: ConnectKOA, index: number) {
        setUsers(prevUsers => (users[index] ? prevUsers.with(index, user) : [...prevUsers, user]));
    }

    useEffect(() => {
        async function getUser() {
            if (!administrator) {
                const account = await getUserAccount();
                if (account) {
                    setUsers([{ username: account.username, gkwNumber: '' }]);
                }
            }
        }
        getUser();
    }, []);

    return (
        <Box className={classes.container}>
            <Typography variant='h1'>
                {koaInProgress ? t('connectKoa.connectKOAForm.anotherKOATitle') : t('connectKoa.connectKOAForm.title')}
            </Typography>
            <Typography className={classes.description}>{t('connectKoa.connectKOAForm.description')}</Typography>
            <Form className={classes.form} onValidSubmit={onDataConfirm}>
                <Box overflow='auto'>
                    {Array.from({ length: userCount }).map((_, index) =>
                        index === 0 ? (
                            <AdministratorFormGroup
                                key={`user${index.toString()}`}
                                user={users[index]}
                                onChange={user => onUserChange(user, index)}
                            />
                        ) : (
                            <UserFormGroup
                                key={`user${index.toString()}`}
                                user={users[index]}
                                index={index}
                                onChange={user => onUserChange(user, index)}
                            />
                        ),
                    )}
                </Box>
                <Box className={classes.buttons}>
                    <Typography variant='body1'>{t('connectKoa.connectKOAForm.multipleGkwNumbers')}</Typography>
                    <ResponsiveButton
                        variant='outlined'
                        color='primary'
                        icon={<AddIcon />}
                        onClick={() => setUserCount(count => count + 1)}
                    >
                        {t('connectKoa.connectKOAForm.addUser')}
                    </ResponsiveButton>
                    <Button variant='contained' size='medium' color='primary' type='submit'>
                        {t('buttons.next')}
                    </Button>
                </Box>
            </Form>
        </Box>
    );
}
