import { createContext, useState, useContext, PropsWithChildren, useMemo } from 'react';
import { UserSetting } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useQuery, useMutation } from '@tanstack/react-query';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { authenticatedRequest, handleApiGetError } from '@api/helper';

interface UserSettingsContextValue {
    isInitialized: boolean;
    userSettings: UserSetting;
    updateUserSettings: (newUserSettings: UserSetting, onSuccess?: () => void) => void;
}

const UserSettingsContext = createContext<UserSettingsContextValue>({
    isInitialized: false,
    userSettings: {},
    updateUserSettings: () => undefined,
});

const client = AuctionPreparationClient.instance.getApiClient();

export function UserSettingsProvider(props: Readonly<PropsWithChildren>) {
    const [userSettings, setUserSettings] = useState<UserSetting>({});
    const [isInitialized, setIsInitialized] = useState(false);
    const { children } = props;
    const saveUserSettings = useMutation((newUserSettings: UserSetting) => client.saveUserSetting(newUserSettings));

    const value = useMemo<UserSettingsContextValue>(
        () => ({
            isInitialized,
            userSettings,
            updateUserSettings,
        }),
        [isInitialized, userSettings],
    );

    useQuery(['user-settings'], () => authenticatedRequest(queryFn), {
        onError: error => {
            handleApiGetError(error);
        },
        onSuccess: (data: UserSetting) => {
            if (data) {
                setIsInitialized(true);
                setUserSettings(data);
            }
        },
    });

    function queryFn() {
        return client.getUserSetting();
    }

    function updateUserSettings(newUserSettings: UserSetting, onSuccess?: () => void) {
        saveUserSettings.mutate(newUserSettings, { onSuccess });

        setUserSettings(newUserSettings);
    }

    return <UserSettingsContext.Provider value={value}>{children}</UserSettingsContext.Provider>;
}

export function useUserSettings(): UserSettingsContextValue {
    return useContext(UserSettingsContext);
}
