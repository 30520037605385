import { DefaultFormGroup } from '@floriday/floriday-ui';
import { Box, Typography } from '@mui/material';
import { ConnectKOA } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useTranslation } from 'react-i18next';

import { namespaces } from '@root/i18n';
import { useStyles } from '../../ConnectKOADataFormStyles';

export interface IConnectKOADataForm {
    readonly user: ConnectKOA;
    readonly index: number;
    readonly onChange: (user: ConnectKOA) => void;
}

export default function UserFormGroup(props: IConnectKOADataForm) {
    const { user, index, onChange } = props;
    const { t } = useTranslation(namespaces.general);
    const { classes } = useStyles();

    return (
        <Box className={classes.userContainer} data-testid='userFormGroup'>
            <Typography fontWeight='bold'>{t('connectKoa.connectKOAForm.user', { count: index + 1 })}</Typography>
            <DefaultFormGroup
                value={user?.username ?? ''}
                onChange={e => onChange({ ...user, username: e.target.value })}
                fullWidth={true}
                placeholder={t('connectKoa.connectKOAForm.email')}
            />
            <DefaultFormGroup
                value={user?.gkwNumber ?? ''}
                onChange={e => onChange({ ...user, gkwNumber: e.target.value })}
                fullWidth={true}
                placeholder={t('connectKoa.connectKOAForm.gkw')}
            />
        </Box>
    );
}
