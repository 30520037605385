import { createContext, useState, useContext, PropsWithChildren, useMemo } from 'react';
import { UserAgreement } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useQuery, useMutation } from '@tanstack/react-query';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { authenticatedRequest, handleApiGetError } from '@api/helper';

interface UserAgreementContextValue {
    isInitialized: boolean;
    agreementMoment?: string;
    updateUserAgreement: () => void;
}

const UserAgreementContext = createContext<UserAgreementContextValue>({
    isInitialized: false,
    updateUserAgreement: () => undefined,
});

const client = AuctionPreparationClient.instance.getApiClient();

export function UserAgreementProvider(props: Readonly<PropsWithChildren>) {
    const [agreementMoment, setAgreementMoment] = useState<string>();
    const [isInitialized, setIsInitialized] = useState(false);
    const { children } = props;
    const saveUserAgreement = useMutation(() => client.saveUserAgreement());

    const value = useMemo<UserAgreementContextValue>(
        () => ({
            isInitialized,
            agreementMoment,
            updateUserAgreement,
        }),
        [isInitialized, agreementMoment],
    );

    useQuery(['user-agreement'], () => authenticatedRequest(queryFn), {
        onError: error => {
            handleApiGetError(error);
        },
        onSuccess: (data: UserAgreement) => {
            if (data) {
                setIsInitialized(true);
                setAgreementMoment(data.agreementMoment);
            }
        },
    });

    function queryFn() {
        return client.getUserAgreement();
    }

    function updateUserAgreement() {
        saveUserAgreement.mutate(undefined, { onSuccess: () => setAgreementMoment(new Date().toISOString()) });
    }

    return <UserAgreementContext.Provider value={value}>{children}</UserAgreementContext.Provider>;
}

export function useUserAgreement(): UserAgreementContextValue {
    return useContext(UserAgreementContext);
}
