import { ContractIcon } from '@floriday/floriday-icons';
import { Box, Link, StepConnector, Typography } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { Trans, useTranslation } from 'react-i18next';

import config from '@config';
import i18n, { namespaces, SupportedLocale } from '@root/i18n';
import PlayButtonIcon from '@resources/Icons/PlayButtonIcon';
import StepIcon from './Components/StepIcon/StepIcon';
import { useStyles } from './ConnectKOAStepInfoStyles';

export interface IConnectKOAStepInfo {
    readonly currentStep: number;
}

export default function ConnectKOAStepInfo(props: IConnectKOAStepInfo) {
    const { currentStep } = props;
    const { t } = useTranslation(namespaces.general);
    const { classes } = useStyles();

    const steps = [
        {
            label: t('connectKoa.stepsOne'),
        },
        {
            label: t('connectKoa.stepsTwo'),
        },
        {
            label: t('connectKoa.stepsThree'),
            description: t('connectKoa.stepsThreeAdditional'),
        },
    ];

    return (
        <Box className={classes.container}>
            <Typography variant='h2'>{t('connectKoa.stepsTitle')}</Typography>
            <Typography className={classes.stepsInfo}>{t('connectKoa.stepsInfo')}</Typography>
            <Stepper
                activeStep={currentStep}
                orientation='vertical'
                connector={<StepConnector className={classes.stepConnector} />}
            >
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            slots={{ stepIcon: StepIcon }}
                            optional={
                                index === steps.length - 1 ? (
                                    <Typography variant='caption' className={classes.stepDescription}>
                                        {step.description}
                                    </Typography>
                                ) : null
                            }
                            className={`${classes.stepLabel} ${index <= currentStep ? classes.stepActive : ''}`}
                        >
                            {step.label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box className={classes.footer}>
                <Typography color='primary' fontWeight='bold' fontSize='18px'>
                    {t('connectKoa.questions')}
                </Typography>
                <Link
                    target='_blank'
                    href={`https://intercom.help/royal-floraholland-buyer-helpcenter/${i18n.language.substring(0, 2)}/articles/9956008`}
                    rel='noreferrer'
                    underline='always'
                    className={classes.footerText}
                >
                    <PlayButtonIcon className={classes.playButtonIcon} />
                    <Typography color='primary'>{t('connectKoa.video')}</Typography>
                </Link>
                <Box className={classes.footerText}>
                    <ContractIcon color='primary' />
                    <Box className={classes.helpCenterText}>
                        <Trans
                            i18nKey='connectKoa.serviceDesk'
                            components={[
                                <Link
                                    key={'helpCenterLink'}
                                    target='_blank'
                                    href={config.helpUrl[i18n.language as SupportedLocale]}
                                    rel='noreferrer'
                                    underline='always'
                                />,
                            ]}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
