import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        height: '100%',
        margin: '0 -16px',
        backgroundImage: 'url(assets/images/koa-bg.png)',
        backgroundSize: 'cover',
    },
    leftSection: {
        width: '33%',
        backgroundColor: theme.palette.grey[400],
        padding: theme.spacing(10),
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down(1800)]: {
            padding: theme.spacing(5),
        },
    },
    rightSection: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        padding: theme.spacing(10, 0),
        [theme.breakpoints.down(1800)]: {
            padding: theme.spacing(5, 0),
        },
        overflow: 'auto',
    },
    content: {
        maxWidth: '614px',
    },
    mobileMessage: {
        padding: theme.spacing(4),
    },
}));
