import { ArrowDownIcon } from '@floriday/floriday-icons';
import { ToggleIconButton } from '@floriday/floriday-ui';
import { ButtonGroup } from '@mui/material';

import { MarkSize } from '@features/Supply/Components/Marking/MarkingContainer/MarkingContainer';
import FlagOutlineIcon from '@resources/Icons/FlagOutlineIcon';
import { usePresetContext } from '@store/Providers/PresetsProvider';
import { useStyles } from './MarkingReadOnlyStyles';
import { useStyles as useMarkButtonMenuStyles } from '../MarkButtonMenu/Components/MarkButtonMenu/MarkButtonMenuStyles';

interface MarkingReadOnlyProps {
    readonly size?: MarkSize;
}

export default function MarkingReadOnly(props: MarkingReadOnlyProps) {
    const { size = MarkSize.Medium } = props;
    const { classes } = useStyles();
    const { classes: markButtonMenuClasses } = useMarkButtonMenuStyles();
    const { readOnlyFloaterOpen, setReadOnlyFloaterOpen } = usePresetContext();

    return (
        <ButtonGroup
            variant='outlined'
            className={`${markButtonMenuClasses.buttonGroup} ${size == MarkSize.Medium ? markButtonMenuClasses.medium : ''} ${size == MarkSize.Small ? markButtonMenuClasses.small : ''}`}
        >
            <ToggleIconButton
                className={classes.button}
                onClick={() => {
                    if (readOnlyFloaterOpen) {
                        document.getElementById('readOnlyFloater')?.focus();
                    }
                    setReadOnlyFloaterOpen(true);
                }}
                icon={<FlagOutlineIcon className={classes.icon} />}
                isSelected={false}
                data-testid='markingReadOnlyButton'
            />
            <ToggleIconButton
                size='small'
                disabled={true}
                className={markButtonMenuClasses.squareIconButton}
                icon={<ArrowDownIcon className={markButtonMenuClasses.toggleIcons} />}
                isSelected={true}
            />
        </ButtonGroup>
    );
}
