import { Dispatch, createContext, useReducer, useContext, PropsWithChildren, useMemo } from 'react';

import { MarkingsActions } from '@store/Actions/markingsActions';
import { initialState, MarkingsState, markingsReducer } from '@store/Reducers/markingsReducer';

interface IMarkingsContext {
    state: MarkingsState;
    dispatch: Dispatch<MarkingsActions>;
}

const initialContext: IMarkingsContext = {
    state: initialState,
    dispatch: () => undefined,
};

const MarkingsContext = createContext(initialContext);

export function MarkingsProvider(props: Readonly<PropsWithChildren>) {
    const { children } = props;
    const [state, dispatch] = useReducer(markingsReducer, initialState);

    const value = useMemo<IMarkingsContext>(
        () => ({
            state,
            dispatch,
        }),
        [state],
    );

    return <MarkingsContext.Provider value={value}>{children}</MarkingsContext.Provider>;
}

export function useMarkingsContext(): IMarkingsContext {
    return useContext(MarkingsContext);
}
